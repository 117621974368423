.alert{
    color: white;
    font-weight: bold;
    padding: 10px 10px 10px 20px;
    border-radius: 13px;
    background-color: #EF5350;
    margin: 0 auto;
    width: 40%;
    height: fit-content;
    z-index:9999; 
    position: fixed;
    left: 50%;
    animation: fadeIn 1s;
    transform: translate(-50%, 0%);
  }

  .close {
    float: right;
    cursor: pointer;
  }
  
.alertDismissable .close {
    background-color: transparent;
    color: darkred;
    border: 0;
    text-align: center;
    
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
