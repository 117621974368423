.Modal {
	width: fit-content;
	height: fit-content;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.Body {
	padding: 0px 50px 20px;
	background-color: white;
	display: grid;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	max-height: fit-content;
	margin-top: 25px;

	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	column-gap: 4%;
}

.SectionTitle {
	color: black;
	font-weight: bold;

	margin-bottom: 15px;
	box-sizing: border-box;
}

.Indicators {
	font-weight: bold;
	font-size: 18.5px;
	color: black;
	margin-bottom: 2px;
}

.Text {
	color: black;
	box-sizing: border-box;
	font-size: 18px;
	grid-column: 1/2;
	/* margin-bottom: px;	 */
}

.Visualization {
	grid-column: 1 / 2;
	display: grid;
	width: 100%;
	height: 100%;
	place-self: stretch;
}

img[id='OriginalViz'] {
	grid-column: 2 / 3;
	width: 90%;
	grid-row: 2/4;
	height: 90%;
	place-self: center;
}

img[id='ValidationViz'] {
	grid-column: 3 / 4;
	width: 90%;
	height: 90%;
	grid-row: 2/4;
	place-self: center;
}

.ring {
	transform: translate(100%, 75%);
	width: 150px;
	height: 150px;
	background: transparent;
	border: 3px solid #3c3c3c;
	border-radius: 50%;
	text-align: center;
	line-height: 150px;
	font-family: sans-serif;
	font-size: 20px;
	color: #3c3c3c;
	letter-spacing: 4px;
	text-transform: uppercase;
	text-shadow: 0 0 10px #f2b457;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.ring:before {
	content: '';
	position: absolute;
	top: -3px;
	left: -3px;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-top: 3px solid #f2b457;
	border-right: 3px solid #f2b457;
	border-radius: 50%;
	animation: animateC 2s linear infinite;
}
.LoadingSpan {
	display: block;
	position: absolute;
	top: calc(50% - 2px);
	left: 50%;
	width: 50%;
	height: 4px;
	background: transparent;
	transform-origin: left;
	animation: animate 2s linear infinite;
}
.LoadingSpan:before {
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #f2b457;
	top: -6px;
	right: -8px;
	box-shadow: 0 0 20px #f2b457;
}
@keyframes animateC {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes animate {
	0% {
		transform: rotate(45deg);
	}
	100% {
		transform: rotate(405deg);
	}
}
