.SearchDiv {
	grid-row: 2/4;
	grid-column: 1/3;
	place-self: left center;
	min-width: 0;
	grid-column: span 4;
	display: grid;
	grid-column: 2fr 1fr;
	margin-top: 10px;
	box-sizing: border-box;
}

.SourceInd {
	grid-column: 1 2;
	grid-row: 1 2;
	place-self: left center;
	box-sizing: border-box;
	display: grid;
}

.SourceIndContainer {
	grid-row: 1/2;
	grid-column: 1/2;
}

.DropDown{
	border: solid #f2b457 2px;
	padding: 2px;
	text-align: left;
	width: 100%;
	grid-column: 1 3;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Container {
	grid-column: 1/4;
	overflow: hidden;
}

.ContainerWrapperPlacing {
	grid-column: 1 / 2;
	grid-row: 1 / 3;
}

img[id="ReplayImage"]{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
