.Footer {
	background-color: black;
	border-top: solid #f2b457 4px;
	text-align: center;
	padding: 10px;
	padding: 30px 0;
	color: #aaa;
	line-height: 1.2;
	width: 100%;
	height: 50px;
	place-self: end center;
	grid-row: 3/4;
	margin-bottom: 0;
}
