.Window {
	background-color: white;
	border: solid black 1px;
	border-top: solid #f2b457 5px;
	display: grid;
	grid-template-columns: 450px fit-content;
	grid-template-rows: 1fr auto;
	column-gap: 15px;

	min-width: 450px;

	max-height: 90vh;
	overflow-y: auto;
	padding: 20px;
	color: black;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Window[id="viewReplay"] {
	min-height: 80vh;
	display: flex;
	justify-content: center;
  	align-items: center;
}

.Title {
	font-weight: bold;
	text-align: center;
	margin: 15px;
	grid-column: 1/2;
	color: black;
	
}

.ImageDiv{
	grid-column: 1/3;
	color: black;
	grid-row: 1/2;
}

.Input {
	border: solid #f2b457 2px;
	margin-top: 0px;
	padding: 2px;
	text-align: left;
	width: 100%;
	grid-column: 1/2;
	font-size: 15px;
}

.Input[type='checkbox'] {
	width: auto;
	place-self: start;
	vertical-align: middle;
}

.InputRange  {
	margin: 10px;
	width: 90%;
	background: #f2b457;
	grid-column: 1/2;
	place-self: center;
}


.Text {
	margin-bottom: 5px;
	grid-column: 1/2;
}

.Instructions{
    font-weight: bold;
    text-align: center;
	grid-column: 1/2;
}


.Error {
	color: red;
    font-size: 10px;
	grid-column: 1/2;
}

.InputHint {
	color: grey;
	font-size: 0.8em;
	grid-column: 1/2;
}

.Data {
	font-weight: 15;
	line-height: 18px;
	grid-column: 1/2;
}

.ViewInput {
	display: flex;
	width: 20%;
	
	margin-left: 1%;
	grid-column: 1/2;
}

.LabelTextDiv {
	display: flex;
	margin: 3% 0;
	font-size: 15px;
	grid-column: 1/2;
}

.VideoKit{
	place-self: start;
	grid-column: 1/2;
	width: 100%;
}

.ButtonsDiv {
	place-self: left center;
	grid-column: 1/2;
}

.ResultsDiv {
	grid-column: 2/3;
	max-height: 28vh;
	overflow-y: auto;
	place-self: start;
	grid-row: 2/3;
}


.MuiTextField-root {
	border-color: #f2b457;
	font-size: 15px;
}

.MuiFormControl-root {
	background-color: red;
}

.CheckboxTextDiv {
	display: flex;
	flex-direction: row;
	column-gap: 2%;
	align-items: center;
	max-height: min-content;
}

.Input[id="sourceCheckbox"] {
	place-self: center;
	align-self: center;
}

.LabelCheckbox{
	display: inline-block;
	margin: 5px;
}

.SpanCheckbox{
	vertical-align: middle;
}