.Container {
    box-shadow: 5px 10px 8px #888888;
    background-color: #C3C3C3;
    border-radius: 0.7%;
	padding: 8px 15px 15px;

	display: grid;

	color: black;
	box-sizing: border-box;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Container[id="VideoAnalytics"] {
	grid-template-columns: 4fr 1fr;
	grid-template-rows: 1fr 3fr;
	row-gap: 0px;

}

.Container[id="AnalysisResults"] {
	grid-template-rows: 90% 10%;
    grid-template-columns: 1fr 2fr 2fr;
	row-gap: 0px;
	margin-bottom: 35px;
	width: 100%;
	max-height: 50%;
	grid-column: span 1;
}

.Container[id="Replay"]{
	grid-template-columns: 2fr 1fr;
}

.Container[id="AnalysisResults"] {
	grid-template-rows: 50px 400px;
    grid-template-columns: 1fr 2fr 2fr;
	margin-bottom: 35px;
	width: 100%;
	grid-column: span 1;
}