img[id='logo'] {
	padding-left: 20px;
	padding-right: 20px;
	float: left;
	width: auto;
	height: auto;
	max-height: 100px;
	grid-column: 1;
}

.title {
	grid-column: 2;
	color: black;
	font-family: 'Oswald';
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 32px;
	grid-area: title;
	align-self: center;
	text-align: center;
	justify-self: center;
	box-sizing: border-box;
}

.header {
	background-color: white;
	border-top: solid #f2b457 4px;
	text-align: center;
	padding: 10px;
	box-shadow: 0px 10px 8px #888888;
	height: 100px;
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
}

.connected {
	color: green;
}

.serverStatus {
	color: black;
	text-align: right;
	grid-column: 3;
}

.disconnected {
	color: red;
}
