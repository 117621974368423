.SearchDiv {
    grid-row: 2/4;
    grid-column: 1/3;
    place-self: left center;
    width: 100%;
    display: grid;
    margin-top: 10px;
    box-sizing: border-box;
    grid-column: 1/3;
}

.DropDown{
    border: solid #f2b457 2px;
    padding: 2px;
    text-align: left;
    width: 100%;
    grid-column: 1 3;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Container {
    grid-column: 1/3;
}

.ContainerWrapperPlacing {
    grid-column: 2/3;
    grid-row: 1 / 3;
}

.ScrollableList {
    overflow-y: auto;
    min-height: 100px;
    max-height: 100px;
    width: 95%;
    padding: 2px;

    background-color: #ffffff;
    border: solid #f2b457 2px;

	text-align: left;
	font-size: 17px;
}

.ListItem:nth-child(odd) {
    background-color: #ffffff;
}

.ListItem:nth-child(even) {
    background-color: #eeeeee;
}

.Selected {
    background-color: #f2b457 !important;
}

.SmallText {
	font-size: 18px;
	color: #555;
    line-height: 1.4;
    margin-top: 20px;
    line-height: 35px;
}

img[id="ValidationViz"]{
	grid-column: 3 / 4;
	width: 90%;
	height: 90%;
	grid-row: 2/4;
	place-self: center;
}

.Image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    grid-column: 1/2;
}

img[id="ReplayImage"]{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    grid-column: 1/2;
    display: inline;
}
