.WrapperContainer {
    grid-column: 1/2;
    grid-row: 2/3;
    min-height: 350px;
    max-height: 350px;
    width: 100%;
    overflow-y: auto;
}

.HeaderResults {
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}