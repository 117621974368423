.Button {
    background-color: #dbdada;
	border-radius: 6px;
	padding: 5px;
	margin: 0px 5px;

	text-align: center;
	font-size: 15px;

	cursor: pointer;

	align-self: start;
	justify-self: right;
	box-sizing: border-box;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    width: max-content;
	float: right;
}

.Button:hover {
    background-color: #f2b457;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: no-drop
}

.Button[id="schedule"] {
	grid-column: 3/4;
	grid-row: 1 / 2;
}

.Button[id="view"] {
	grid-column: 3/4;
	grid-row: 2 / 2;
}


.Button[id='Close'] {
	float: left;
	place-self: start;
	display: flex;
	grid-column: 1/2;
}

.Button[id='deleteSource'] {
	grid-column: 3/4;
	grid-row: 1/2;
	display: flex;
	place-self: start;
}

.Button[id="Clear"] {
	place-self: start;
	grid-column: 1/2;
	margin-bottom: 5px;
	display: flex;
}

.Button[id='Download'] {
	place-self: end;
	grid-column: 2/3;
}

.Button[id="AddSource"]{
	grid-column: 3/4;
	grid-row: 1/2;
	display: flex;

}