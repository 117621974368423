h1 {
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Tips {
    color: white;
}

.ErrorDiv {
    background-color: #f2b457;
    height: 40%;
    
    padding: 150px;
    margin: 50px 50px 32px;
}
